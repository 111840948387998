import React from "react";
import { Container } from "react-bootstrap";
import getImagePath from "helpers/get-image-path";

import {
  fullTwoColumns, fullTwoColumnsItems, fullTwoColumnsText,
  fullTwoColumnsImg,
} from "./style.mod.scss";
import RichText from "components/rich-text";

const FullTwoColumns = ({ data }) => {
  const { image, text } = data;
  const alt = image.alt;
  
  return (
    <div className={fullTwoColumns}>
      <img src={getImagePath(image)} className={fullTwoColumnsImg} alt={alt} />
      <Container>
        <div className={fullTwoColumnsItems}>
          <RichText blocks={text} className={fullTwoColumnsText} />  
        </div>
      </Container>
    </div>
  );
};

export default FullTwoColumns;
